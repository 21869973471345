<template>
    <div class="task-index__modal" v-if="success && task">
        <div class="task-index__overlay" @click="closeTask"></div>
        <div class="task-index__inner">
            <div class="task-index__main">
                <div class="task-index__head">
                    <TaskTitle :name="task.name"/>

                    <div class="task-index__top">
                        <div class="task-index__parent" v-if="task.parent && task.parent.id">
                          <span class="task-index__parent-label">
                              Подзадача для:
                          </span>
                          <router-link class="task-index__parent-link" target="_blank" :to="{name: 'task', params: {taskId: task.parent.id}}">
                            {{ task.parent.name }}
                          </router-link>
                        </div>

                        <div class="task-index__proj" v-if="task.project">
                          <span class="task-index__proj-label">
                              Проект:
                          </span>
                          <router-link class="task-index__proj-link" target="_blank" :to="{name: 'project_tasks', params: {projectId: task.project.id}}">
                            {{ task.project.name }}
                          </router-link>
                        </div>
                    </div>

                    <ul class="task-index__tabs">
                        <li class="task-index__tab" v-for="tab in tabs">
                            <a href="javascript:void(0)" class="task-index__tab-link" :class="{'_active': tab.key === currentTab }" @click="setTab(tab.key)">
                                {{ tab.name }}
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="task-index__tabs-content">
                    <div class="task-index__tab-content" v-if="currentTab === 'state'">
                        <div class="task-index__subhead">
                            <TaskWorkers/>
                        </div>
                        <TaskTime :task="task"/>
                        <TaskDescription/>
                        <TaskSubtasks :task="task"/>
                        <TaskAttachments/>
                        <TaskComments/>
                    </div>
                    <div class="task-index__tab-content" v-if="currentTab === 'history'">
                        <TaskLog :task-id="task.id"/>
                    </div>
                </div>

            </div>
            <div class="task-index__right">
                <TaskDeadline :task="task"/>
                <div class="task-index__tags" v-if="task.tags && task.tags.length > 0">
                    <div class="task-index__tag" v-for="tag in task.tags" :style="{'background-color': tag.color}">
                        <div class="task-index__tag-circle"></div>
                        <div class="task-index__tag-name">
                            {{ tag.name }}
                        </div>
                    </div>
                </div>
                <TaskOptions @close="onClose"/>
                <TaskRepeater/>
                <TaskSupport/>
                <div class="task-index__additional task-index__additional_first">
                    <div class="task-index__additional-item">
                        <span class="task-index__additional-label">Дата постановки:</span>
                        <span class="task-index__additional-value">{{dateTime}}</span>
                    </div>
                    <div class="task-index__additional-item" v-if="task.owner">
                        <span class="task-index__additional-label">Постановщик:</span>
                        <span class="task-index__additional-value">{{task.owner.name}}</span>
                    </div>
                    <div class="task-index__additional-item" v-if="taskPerformer">
                        <span class="task-index__additional-label">Исполнитель:</span>
                        <span class="task-index__additional-value">{{ taskPerformer.name }}</span>
                    </div>
                    <div class="task-index__additional-item" v-if="task.status">
                        <span class="task-index__additional-label">Статус:</span>
                        <span class="task-index__additional-value">{{ statusName }}</span>
                    </div>
                    <div class="task-index__additional-item" v-if="task.isArchive">
                        <span class="task-index__additional-label">Статус:</span>
                        <span class="task-index__additional-value">Задача в архиве</span>
                    </div>
                </div>
                <TaskProject/>

                <p class="task-index__spent" v-if="task.spent_time > 0" title="Часов:Минут">🕒 Потрачено - {{ spentFormatted }}</p>
            </div>
        </div>
    </div>
</template>

<script>
  import IconSketch from "../icons/IconSketch.vue";
  import IconCrossSmall from "../icons/IconCrossSmall.vue";
  import IconWord from "../icons/IconWord.vue";
  import IconPS from "../icons/IconPS.vue";
  import IconZip from "../icons/IconZip.vue";
  import IconPen from "../icons/IconPen.vue";
  import TaskTitle from "../components/app/task/TaskTitle.vue";
  import TaskStatus from "../components/app/task/TaskStatus.vue";
  import TaskTagsList from "../components/app/task/TaskTagsList.vue";
  import TaskWorkers from "../components/app/task/TaskWorkers.vue";
  import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
  import TaskDescription from "../components/app/task/TaskDescription.vue";
  import TaskAttachments from "../components/app/task/TaskAttachments.vue";
  import UploadService from "../services/upload.service";
  import TaskComments from "../components/app/task/TaskComments.vue";
  import TaskDeadline from "../components/app/task/TaskDeadline.vue";
  import TaskOptions from "../components/app/task/TaskOptions.vue";
  import TaskTime from "../components/app/task/TaskTime.vue";
  import TaskProject from "../components/app/task/TaskProject.vue";
  import TaskSupport from "../components/app/task/TaskSupport.vue";
  import TaskRepeater from "../components/app/task/TaskRepeater.vue";
  import TaskLog from "../components/app/task/TaskLog";
  import TaskSubtasks from "../components/app/task/TaskSubtasks.vue";

  export default {
    name: "TaskEditModal",
    components: {
      TaskSubtasks,
      TaskLog,
      TaskOptions,
      TaskDeadline,
      TaskComments,
      TaskAttachments,
      TaskDescription,
      TaskWorkers,
      TaskTagsList,
      TaskTime,
      TaskProject,
      TaskSupport,
      TaskRepeater,
      TaskStatus, TaskTitle, IconPen, IconZip, IconPS, IconWord, IconCrossSmall, IconSketch
    },
    computed: {
      ...mapState('task', [
        'task'
      ]),
      ...mapState('worker', [
        'selectedWorkers'
      ]),
      ...mapGetters('filter', [
        'filters'
      ]),
      ...mapGetters('task', [
        'taskPerformer'
      ]),
      dateTime() {
        if (this.task.created_at !== undefined) {
          return this.$moment(this.task.created_at).calendar(null, {
            sameDay: '[Сегодня] HH:mm',
            lastDay: '[Вчера] HH:mm',
            lastWeek: 'D MMM HH:mm',
            nextDay: '[Завтра] HH:mm',
            nextWeek: 'D MMM HH:mm',
            sameElse: 'D MMM HH:mm'
          });
        }
      },
      statusName() {
        const statuses = this.$store.state.project.statuses;
        for (let status in statuses) {
          if (status == this.task.status) {
            return statuses[status];
          }
        }
        return '';
      },
      tabs() {
        return [
          {
            name: 'State',
            key: 'state'
          },
          {
            name: 'History',
            key: 'history'
          },
        ];
      },
      spentFormatted() {
        let hours = Math.floor(this.task.spent_time / 3600);
        let minutes = Math.floor((this.task.spent_time - (hours * 3600)) / 60);
        return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0')
      }
    },
    data() {
      return {
        success: false,
        currentTab: 'state'
      }
    },
    methods: {
      ...mapActions({
        fetchTasks: 'project/fetchTasks',
        fetchTask: 'task/fetchTask',
      }),
      ...mapMutations({
        clearTask: 'task/clearTask',
      }),
      closeTask() {
        this.toTasks();
      },
      onClose() {
        this.$emit('close');
      },
      handleProjectClick() {
        this.toTasks();
      },
      toTasks() {
        if (this.$route.name === 'projects_project_task') {
          this.$router.push({name: 'projects_project'});
          return;
        }

        if (this.$route.name === 'projects_support_task') {
          this.$router.push({name: 'projects_support'});
          return;
        }

        if (this.$route.params.projectId) {
          this.$router.push({name: 'project_tasks', params: {projectId: this.task.project.id}})
        } else {
          this.$router.push({name: 'projects'})
        }
      },
      setTab(tab) {
        this.currentTab = tab;
      }
    },
    async created() {
      this.$store.state.loading = true;
      Promise.all([
        this.$store.dispatch('task/fetchTask', this.$route.params.taskId),
        this.$store.dispatch('task/fetchComments', this.$route.params.taskId),
        this.$store.dispatch('task/fetchExecutors', this.$route.params.taskId),
      ])
        .then(() => {
          this.$store.state.loading = false;
          this.success = true;

          this.$nextTick(() => {
            const upload = new UploadService({
              button: '.upload-button',
              task_id: this.$route.params.taskId,
              endpoint: '/api/file'
            });

            const uploadNew = new UploadService({
              button: '.upload-button-new',
              task_id: this.$route.params.taskId,
              endpoint: '/api/file'
            });
          });
        })
        .catch((e) => {
          console.log(e);
        })
    },
    mounted() {
      setTimeout(() => {
        console.log(this.task);
      }, 1000);
    },
    beforeDestroy() {
      this.clearTask();
    }
  }
</script>

<style lang="scss">
    .v--modal {
        &-overlay {
            background: rgba(0, 0, 0, 0.7) !important;
        }
    }

    .fade-enter-active, .fade-leave-active {
        /*transition: opacity .02s;*/
        opacity: 1;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
    {
        opacity: 0;
    }

</style>